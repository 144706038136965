export const PAGE_LIMIT = 5;
const PRODUCT_LIMIT = 8;
const CATEGORY_LIMIT = 8;
const BIG_LIMIT = 1000;

export const configureProductCategory = (utils, dispatch, state) => ({
  initialize: () => {
    const category_fetch_list_request = {
      dispatch,
      request: ["product", "category", "list"],
      request_info: { limit: PAGE_LIMIT, offset: 0 },
      auth: false,
      key: "category_page_1",
    };
    utils.action(category_fetch_list_request);
  },
  data: {
    category: state["product"],
    category_fetch_list: state["product"]?.category_list,
    category_fetch_item: state["product"]?.category_item,
    category_create_item: state["product"]?.category_item,
    category_update_item: state["product"]?.category_item,
    category_delete_item: state["product"]?.deleted_category,
  },
  action: {
    fetchCategoryList: () => {
      const category_fetch_list_request = {
        dispatch,
        request: ["product", "category", "list"],
        request_info: {},
        auth: false,
      };
      utils.action(category_fetch_list_request);
    },
    getPages: (number) => {
      const category_fetch_list_request = {
        dispatch,
        request: ["product", "category", "list"],
        request_info: {
          limit: PAGE_LIMIT,
          offset: PAGE_LIMIT * ((number || 1) - 1),
        },
        auth: false,
        key: `category_page_${number}`,
      };
      utils.action(category_fetch_list_request);
    },
    mergePages: (data) => {
      const keys = Object.keys(data.category).filter(
        (key) =>
          data.category[key] &&
          typeof data.category[key] === "object" &&
          key.substring(0, 14) === "category_page_"
      );
      return keys.map((k) => data.category[k].results).flat(1);
    },
    fetchCategoryItem: (id) => {
      const category_fetch_item_request = {
        dispatch,
        request: ["product", "category", "read"],
        request_info: { id },
        auth: false,
      };
      utils.action(category_fetch_item_request);
    },
    createCategoryItem: async (payload) => {
      const category_create_item_request = {
        dispatch,
        request: ["product", "category", "create"],
        request_info: payload,
        auth: true,
      };
      await utils.action(category_create_item_request);
    },
    updateCategoryItem: async (id, payload) => {
      let request_info;

      if (typeof payload.image === "string") {
        const { image, ...remainingData } = payload;
        request_info = { id, ...remainingData };
      } else {
        request_info = { id, ...payload };
      }
      const category_update_item_request = {
        dispatch,
        request: ["product", "category", "update"],
        request_info,
        auth: true,
      };
      await utils.action(category_update_item_request);
    },
    deleteCategoryItem: async (id) => {
      const category_delete_item_request = {
        dispatch,
        request: ["product", "category", "delete"],
        request_info: { id },
        auth: true,
      };
      await utils.action(category_delete_item_request);
    },
  },
});

export const configureProductProduct = (utils, dispatch, state) => ({
  initialize: () => {
    const product_fetch_list_request = {
      dispatch,
      request: ["product", "product", "list"],
      request_info: { limit: PAGE_LIMIT, offset: 0 },
      auth: false,
      key: "product_page_1",
    };
    utils.action(product_fetch_list_request);
  },
  data: {
    product: state["product"],
    product_fetch_list: state["product"]?.product_list,
    product_fetch_item: state["product"]?.product_item,
    product_create_item: state["product"]?.product_item,
    product_update_item: state["product"]?.product_item,
    product_delete_item: state["product"]?.deleted_product,
  },
  action: {
    fetchProductListBySearch: (key) => {
      const product_fetch_list_request = {
        dispatch,
        request: ["product", "product", "list"],
        request_info: { key },
        auth: false,
      };
      utils.action(product_fetch_list_request);
    },
    getPages: (number) => {
      const fetchProductData = {
        dispatch,
        request: ["product", "product", "list"],
        request_info: {
          limit: PAGE_LIMIT,
          offset: PAGE_LIMIT * ((number || 1) - 1),
        },
        auth: false,
        key: `product_page_${number}`,
      };
      utils.action(fetchProductData);
    },
    mergePages: (data) => {
      const keys = Object.keys(data.product).filter(
        (key) =>
          data.product[key] &&
          typeof data.product[key] === "object" &&
          key.substring(0, 17) === "product_cat_page_"
      );
      return keys.map((k) => data.product[k].results).flat(1);
    },
    fetchProductListByCategory: (category) => {
      const product_fetch_list_request = {
        dispatch,
        request: ["product", "product", "list"],
        request_info: { limit: PRODUCT_LIMIT, offset: 0, category },
        auth: false,
        key: "product_cat_page_1",
      };
      utils.action(product_fetch_list_request);
    },
    getCategoryProductPages: (category, number) => {
      const fetchProductData = {
        dispatch,
        request: ["product", "product", "list"],
        request_info: {
          limit: PRODUCT_LIMIT,
          offset: PRODUCT_LIMIT * ((number || 1) - 1),
          category,
        },

        auth: false,
        key: `product_cat_page_${number}`,
      };
      utils.action(fetchProductData);
    },
    fetchProductList: (is_pinned) => {
      const product_fetch_list_request = {
        dispatch,
        request: ["product", "product", "list"],
        request_info: { is_pinned },
        auth: false,
      };
      utils.action(product_fetch_list_request);
    },
    fetchProductItem: (id) => {
      const product_fetch_item_request = {
        dispatch,
        request: ["product", "product", "read"],
        request_info: { id },
        auth: false,
      };
      utils.action(product_fetch_item_request);
    },
    createProductItem: async (payload) => {
      const product_create_item_request = {
        dispatch,
        request: ["product", "product", "create"],
        request_info: payload,
        auth: true,
      };
      await utils.action(product_create_item_request);
    },
    updateProductItem: async (id, payload) => {
      let request_info;

      if (typeof payload.image === "string") {
        const { image, ...remainingData } = payload;
        request_info = { id, ...remainingData };
      } else {
        request_info = { id, ...payload };
      }
      const product_update_item_request = {
        dispatch,
        request: ["product", "product", "update"],
        request_info,
        auth: true,
      };
      await utils.action(product_update_item_request);
    },
    deleteProductItem: async (id) => {
      const product_delete_item_request = {
        dispatch,
        request: ["product", "product", "delete"],
        request_info: { id },
        auth: true,
      };
      await utils.action(product_delete_item_request);
    },
  },
});
