export const PAGE_LIMIT = 5;
const BIG_LIMIT = 1000;

export const configurePaymentPaymentmethod = (utils, dispatch, state) => ({
  initialize: () => {},
  data: {
    paymentmethod_fetch_list: state["payment"]?.paymentmethod_list,
    paymentmethod_fetch_item: state["payment"]?.paymentmethod_item,
    paymentmethod_create_item: state["payment"]?.paymentmethod_item,
    paymentmethod_update_item: state["payment"]?.paymentmethod_item,
    paymentmethod_delete_item: state["payment"]?.deleted_paymentmethod,
  },
  action: {
    fetchPaymentmethodList: () => {
      const paymentmethod_fetch_list_request = {
        dispatch,
        request: ["payment", "paymentmethod", "list"],
        request_info: {},
        auth: false,
      };
      utils.action(paymentmethod_fetch_list_request);
    },
    fetchPaymentmethodItem: (id) => {
      const paymentmethod_fetch_item_request = {
        dispatch,
        request: ["payment", "paymentmethod", "read"],
        request_info: { id },
        auth: true,
      };
      utils.action(paymentmethod_fetch_item_request);
    },
    createPaymentmethodItem: (payload) => {
      const paymentmethod_create_item_request = {
        dispatch,
        request: ["payment", "paymentmethod", "create"],
        request_info: payload,
        auth: true,
      };
      utils.action(paymentmethod_create_item_request);
    },
    updatePaymentmethodItem: (id, payload) => {
      const paymentmethod_update_item_request = {
        dispatch,
        request: ["payment", "paymentmethod", "update"],
        request_info: { id, ...payload },
        auth: true,
      };
      utils.action(paymentmethod_update_item_request);
    },
    deletePaymentmethodItem: (id) => {
      const paymentmethod_delete_item_request = {
        dispatch,
        request: ["payment", "paymentmethod", "delete"],
        request_info: { id },
        auth: true,
      };
      utils.action(paymentmethod_delete_item_request);
    },
  },
});

export const configurePaymentPaymenttransaction = (utils, dispatch, state) => ({
  initialize: () => {
    const paymenttransaction_list = {
      dispatch,
      request: ["payment", "paymenttransaction", "list"],
      request_info: { limit: PAGE_LIMIT, offset: 0 },
      auth: true,
      key: "payment_page_1",
    };
    utils.action(paymenttransaction_list);
  },
  data: {
    paymenttransaction: state["payment"],
    paymenttransaction_fetch_list: state["payment"]?.paymenttransaction_list,
    paymenttransaction_fetch_item: state["payment"]?.paymenttransaction_item,
    paymenttransaction_create_item: state["payment"]?.paymenttransaction_item,
    paymenttransaction_update_item: state["payment"]?.paymenttransaction_item,
    paymenttransaction_delete_item:
      state["payment"]?.deleted_paymenttransaction,
  },
  action: {
    fetchPaymenttransactionList: () => {
      const paymenttransaction_fetch_list_request = {
        dispatch,
        request: ["payment", "paymenttransaction", "list"],
        request_info: {},
        auth: true,
      };
      utils.action(paymenttransaction_fetch_list_request);
    },
    getPages: (number) => {
      const paymenttransaction_list = {
        dispatch,
        request: ["payment", "paymenttransaction", "list"],
        request_info: {
          limit: PAGE_LIMIT,
          offset: PAGE_LIMIT * ((number || 1) - 1),
        },
        auth: true,
        key: `payment_page_${number}`,
      };
      utils.action(paymenttransaction_list);
    },
    fetchPaymenttransactionItem: (id) => {
      const paymenttransaction_fetch_item_request = {
        dispatch,
        request: ["payment", "paymenttransaction", "read"],
        request_info: { id },
        auth: true,
      };
      utils.action(paymenttransaction_fetch_item_request);
    },
    createPaymenttransactionItem: (payload) => {
      const paymenttransaction_create_item_request = {
        dispatch,
        request: ["payment", "paymenttransaction", "create"],
        request_info: payload,
        auth: false,
      };
      utils.action(paymenttransaction_create_item_request);
    },
    updatePaymenttransactionItem: (id, payload) => {
      const paymenttransaction_update_item_request = {
        dispatch,
        request: ["payment", "paymenttransaction", "update"],
        request_info: { id, ...payload },
        auth: true,
      };
      utils.action(paymenttransaction_update_item_request);
    },
    deletePaymenttransactionItem: (id) => {
      const paymenttransaction_delete_item_request = {
        dispatch,
        request: ["payment", "paymenttransaction", "delete"],
        request_info: { id },
        auth: true,
      };
      utils.action(paymenttransaction_delete_item_request);
    },
  },
});
