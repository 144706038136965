import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Form,
  Button,
  Modal,
  Header,
  Icon,
  Divider,
} from "semantic-ui-react";
import BGImageTheme from "../theme/bgimage";
import {
  createUserToken,
  useGoogleUserLogin,
} from "services/api-data/src/utils/auth";
import CaptchaVerification from "components/captcha";
import { useDispatch, useSelector } from "react-redux";
import { configureAccountUserprofile } from "services/access/account";
import { utils } from "services/api-data/src/App";
import { useGoogleLogin } from "@react-oauth/google";

export const ForgotPasswordModal = ({ open, setOpen }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)} dimmer="inverted">
      <Modal.Header>Forgot your Password? </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            {" "}
            <h3> Contact us at following details </h3>
          </p>{" "}
          <p style={{ fontSize: 16 }}>
            {" "}
            Email: <b> sushibelgium.be@gmail.com</b>{" "}
          </p>
          <p style={{ fontSize: 16 }}>
            Phone: <b> 0489506139 </b>{" "}
          </p>
          <p style={{ fontSize: 16 }}>
            <i>
              Don't forget to mention your login email while contacting us!!{" "}
            </i>
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={() => setOpen(false)}>
          {" "}
          Close{" "}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [captchaVerify, setCaptchaVerify] = useState(false);
  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureAccountUserprofile(
    utils,
    dispatch,
    state
  );

  const loggedIn = () => {
    action.fetchUserprofileItem(user_id);
  };

  const login = useGoogleUserLogin(loggedIn);

  useEffect(() => {
    if (user_id) {
      action.fetchUserprofileItem(user_id);
    }
  }, [user_id]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const tokens = await createUserToken(formData);
    setLoading(false);
  };

  useEffect(() => {
    if (data?.userprofile_fetch_item) {
      localStorage.setItem(
        "profile_type",
        data?.userprofile_fetch_item?.profile_type
      );
    }
  }, [data?.userprofile_fetch_item]);

  useEffect(() => {
    if (data?.userprofile_fetch_item) {
      const profile_type = localStorage.getItem("profile_type");
      if (profile_type === "SUPPLIER") {
        navigate("/admin/dashboard");
      } else {
        navigate("/");
      }
    }
  }, [data?.userprofile_fetch_item]);

  return (
    <BGImageTheme theme="auth">
      <Container text>
        <h2 style={{ color: "white" }}>Admin Login</h2>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Field>
            <label style={{ color: "white" }}>Email</label>
            <input
              type="text"
              placeholder="Enter email"
              value={formData?.username}
              onChange={(e) =>
                setFormData({ ...formData, username: e.target.value })
              }
              required
            />
          </Form.Field>
          <Form.Field>
            <label style={{ color: "white" }}>Password</label>
            <input
              type="password"
              placeholder="Enter password"
              value={formData?.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
              required
            />
          </Form.Field>
          <div style={{ padding: 10, backgroundColor: "white" }}>
            <CaptchaVerification {...{ captchaVerify, setCaptchaVerify }} />
            <br />
            <Button
              primary
              disabled={!captchaVerify || loading}
              loading={loading}
              fluid
              size="large"
              onClick={handleLogin}
            >
              Login
            </Button>

            <Divider horizontal> Or </Divider>
            <Button
              color="google plus"
              fluid
              size="large"
              onClick={login}
              disabled={loading}
              loading={loading}
            >
              <Icon name="google" />
              Sign in with Google
            </Button>

            <br />
            <span
              style={{
                cursor: "pointer",
                fontSize: 16,
              }}
              onClick={() => setOpen(true)}
            >
              {" "}
              <u>Forgot Password?</u>{" "}
            </span>
          </div>
        </Form>
        <ForgotPasswordModal open={open} setOpen={setOpen} />
      </Container>
    </BGImageTheme>
  );
};

export default Login;
