import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Loader,
  Segment,
  TextArea,
} from "semantic-ui-react";
import { configureAccountUserprofile } from "services/access/account";
import { utils } from "services/api-data/src/App";
import { clearUserData } from "services/api-data/src/utils/tokenHandler";
import ImageView from "./ImageView";

const ProfileEditForm = ({ editForm, setEditForm }) => {
  return (
    <>
      <Form style={{ paddingRight: 50, paddingTop: 20 }}>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <label> First Name </label>
            <Input
              value={editForm?.first_name}
              onChange={(e) => {
                setEditForm({ ...editForm, first_name: e.target.value });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label> Last Name </label>
            <Input
              value={editForm?.last_name}
              onChange={(e) => {
                setEditForm({ ...editForm, last_name: e.target.value });
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label> Bio </label>
          <TextArea
            value={editForm?.bio}
            onChange={(e) => setEditForm({ ...editForm, bio: e.target.value })}
          />
        </Form.Field>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <label> Email </label>
            <Input
              type="email"
              value={editForm?.email}
              onChange={(e) =>
                setEditForm({ ...editForm, email: e.target.value })
              }
            />
          </Form.Field>
          <Form.Field>
            <label>Website </label>
            <Input
              value={editForm?.website}
              onChange={(e) =>
                setEditForm({ ...editForm, website: e.target.value })
              }
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <label>Contact Number</label>
            <Input
              type="number"
              value={editForm?.contact_number}
              onChange={(e) =>
                setEditForm({ ...editForm, contact_number: e.target.value })
              }
            />
          </Form.Field>
          <Form.Field>
            <label> Business Name </label>
            <Input
              value={editForm?.business_name}
              onChange={(e) =>
                setEditForm({ ...editForm, business_name: e.target.value })
              }
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <label>Address</label>
            <Input
              value={editForm?.address}
              onChange={(e) =>
                setEditForm({ ...editForm, address: e.target.value })
              }
            />
          </Form.Field>
          <Form.Field>
            <label>City</label>
            <Input
              value={editForm?.city}
              onChange={(e) =>
                setEditForm({ ...editForm, city: e.target.value })
              }
            />
          </Form.Field>
          <Form.Field>
            <label>State</label>
            <Input
              value={editForm?.state}
              onChange={(e) =>
                setEditForm({ ...editForm, state: e.target.value })
              }
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <label> Postal Code </label>
            <Input
              type="number"
              value={editForm?.postal_code}
              onChange={(e) =>
                setEditForm({ ...editForm, postal_code: e.target.value })
              }
            />
          </Form.Field>
          <Form.Field>
            <label> Country </label>
            <Input
              value={editForm?.country}
              onChange={(e) =>
                setEditForm({ ...editForm, country: e.target.value })
              }
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </>
  );
};

const ProfileCard = () => {
  const [editView, setEditView] = useState(false);
  const [editForm, setEditForm] = useState();
  const imageInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const profile_type = localStorage.getItem("profile_type");
  const [profileData, setProfileData] = useState();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureAccountUserprofile(
    utils,
    dispatch,
    state
  );

  useEffect(() => {
    action.fetchUserprofileItem(user_id);
  }, [user_id]);

  useEffect(() => {
    if (data?.userprofile_fetch_item) {
      setLoading(data?.userprofile_fetch_item_loading);
      setProfileData(data?.userprofile_fetch_item);
    }
  }, [data?.userprofile_fetch_item]);

  const handleProfileChange = (e) => {
    const file = e.target.files[0];
    setEditForm({ ...editForm, profile_pic: file });
  };

  const handleSave = () => {
    setEditView(false);

    action.updateUserprofileItem(user_id, editForm);
  };

  const renderAddress = () => {
    const { country, state, city, address, postal_code } = profileData || {};
    const addressParts = [
      country && ` ${country}`,
      state && ` ${state}`,
      city && ` ${city}`,
      address && ` ${address}`,
      postal_code && ` ${postal_code}`,
    ]
      .filter(Boolean)
      .join(",");

    return addressParts ? (
      <Grid.Row>
        <Grid.Column>
          <Icon name="map pin" /> {addressParts}
        </Grid.Column>
      </Grid.Row>
    ) : (
      <Grid.Row>
        <Grid.Column>No address details found</Grid.Column>
      </Grid.Row>
    );
  };
  return (
    <>
      {loading ? (
        <Loader active inline />
      ) : (
        <Segment>
          <Grid doubling divided columns={"equal"}>
            <Grid.Row>
              <Grid.Column width={4}>
                <Grid.Row centered>
                  <Grid.Column>
                    <ImageView url={profileData?.profile_pic} height={300} />
                    {editView && (
                      <>
                        <input
                          type="file"
                          fluid
                          onChange={(e) => handleProfileChange(e)}
                          ref={imageInput}
                          style={{ display: "none" }}
                        />
                        <Button
                          color="black"
                          fluid
                          style={{ marginTop: 10 }}
                          onClick={() => imageInput.current.click()}
                          disabled={data.avatar_loading}
                        >
                          {" "}
                          Upload Profile Image{" "}
                        </Button>
                      </>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column>
                <div
                  style={{
                    position: "absolute",
                    right: 5,
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  {editView ? (
                    <>
                      <Button
                        primary
                        basic
                        icon="save outline"
                        onClick={() => handleSave()}
                      />
                      <Button
                        negative
                        basic
                        icon="close"
                        onClick={() => setEditView(false)}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        primary
                        basic
                        icon="edit outline"
                        onClick={() => {
                          setEditView(true);
                          setEditForm(profileData);
                        }}
                      />
                      <Button
                        basic
                        icon
                        negative
                        onClick={() => clearUserData()}
                      >
                        {" "}
                        <Icon name="log out" />
                        Sign Out
                      </Button>
                      {profile_type === "SUPPLIER" && (
                        <Button as={Link} to="/admin/dashboard" basic secondary>
                          Go to Dashboard
                        </Button>
                      )}
                    </>
                  )}
                </div>
                {editView ? (
                  <ProfileEditForm
                    editForm={editForm}
                    setEditForm={setEditForm}
                  />
                ) : (
                  <div>
                    <h3> Profile Details </h3>
                    <Grid>
                      {profileData?.first_name && profileData?.last_name && (
                        <Grid.Row>
                          <Grid.Column>
                            <span>
                              <b> Name: </b> {profileData?.first_name}{" "}
                              {profileData?.last_name}
                            </span>
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      {profileData?.bio && (
                        <Grid.Row>
                          <Grid.Column>
                            <span>
                              <b>Bio: </b>
                              {profileData.bio}
                            </span>
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      {profileData?.email && (
                        <Grid.Row>
                          <Grid.Column>
                            <span>
                              <b> Email: </b>
                              {profileData?.email}
                            </span>
                          </Grid.Column>
                        </Grid.Row>
                      )}

                      {profileData?.business_name && (
                        <Grid.Row>
                          <Grid.Column>
                            <span>
                              <b>Business Name: </b>
                              {profileData?.business_name}
                            </span>
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      {profileData?.website && (
                        <Grid.Row>
                          <Grid.Column>
                            <span>
                              <b>Website: </b> {profileData?.website}
                            </span>
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      {profileData?.contact_number && (
                        <Grid.Row>
                          <Grid.Column>
                            <span>
                              {" "}
                              <b> Contact Number: </b>{" "}
                              {profileData?.contact_number}{" "}
                            </span>
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      <Grid.Row>
                        <Grid.Column>
                          <Segment style={{ marginTop: 5 }}>
                            <Header as={"h4"}>Address Detail</Header>
                            <Grid>{renderAddress()}</Grid>
                          </Segment>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      )}
    </>
  );
};

export default ProfileCard;
