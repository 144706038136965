import Footer from "../components/footer";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { Container, Menu, Image, Icon, Grid, Button } from "semantic-ui-react";
import Sidebar from "components/nav/sidebar";
import Logo from "assets/logo.png"; // Assuming Logo is an image path

const Layout = ({ activeItem, children }) => {
  return (
    <>
      <div style={{ display: "flex" }}>
        <div>
          <Sidebar activeItem={activeItem} />
        </div>
        <div style={{ flex: 1 }}>
          <Grid style={{ backgroundColor: "#222831", padding: 0, margin: 0 }}>
            <Grid.Row>
              <Grid.Column>
                <Button
                  as={Link}
                  to="/"
                  icon
                  style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                >
                  <Icon name="home" style={{ color: "white" }} size="large" />
                </Button>
              </Grid.Column>
              <Grid.Column as={Link} to="/admin/dashboard">
                <div
                  style={{
                    fontSize: 26,
                    color: "white",
                    width: 300,
                    marginTop: 10,
                  }}
                >
                  Sushi Belgium
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid style={{ paddingBottom: "30px" }}>{children}</Grid>
        </div>
      </div>
      <Footer style={{ backgroundColor: "#222831" }} />
    </>
  );
};

export default Layout;
