import { useGoogleLogin } from "@react-oauth/google";
import { API_BASE_URL } from "App";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useGoogleUserLogin = ({ loggedIn }) => {
  const navigate = useNavigate();
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: { "Content-Type": "application/json" },
  });

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const data = { access_token: tokenResponse.access_token };
        const res = await axiosInstance.post("/account/google-login/", data);
        toast.success("You have successfully logged in!");
        const token = res.data;
        setUserData(token);
        // navigate("/");
        loggedIn();
        return res.data;
      } catch (error) {
        toast.error(
          "Login failed. Please check your credentials and try again."
        );
      }
    },
    onError: (error) => {
      toast.error("Login failed. Try again.");
    },
  });

  return login;
};

export async function createUserToken(data) {
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: { "Content-Type": "application/json" },
  });
  try {
    const res = await axiosInstance.post("/user-token/", data);
    toast.success("You have successfully logged in!");
    const token = res.data;
    setUserData(token);
    return res.data;
  } catch (error) {
    toast.error("Login failed. Please check your credentials and try again.");
  }
}

export const setUserData = (value) => {
  const accessToken = value.access;
  const decoded = jwtDecode(accessToken);
  const user_id = decoded.user_id;
  if (typeof localStorage !== "undefined") {
    localStorage.setItem("tk", JSON.stringify(value));
    localStorage.setItem("user_id", user_id);
  }
};
