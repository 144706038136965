const PAGE_LIMIT = 8;
const BIG_LIMIT = 1000;

export const configurePricingTax = (utils, dispatch, state) => ({
  initialize: () => {},
  data: {
    tax_fetch_list: state["pricing"]?.tax_list,
    tax_fetch_item: state["pricing"]?.tax_item,
    tax_create_item: state["pricing"]?.tax_item,
    tax_update_item: state["pricing"]?.tax_item,
    tax_delete_item: state["pricing"]?.deleted_tax,
  },
  action: {
    fetchTaxList: () => {
      const tax_fetch_list_request = {
        dispatch,
        request: ["pricing", "tax", "list"],
        request_info: {},
        auth: true,
      };
      utils.action(tax_fetch_list_request);
    },
    fetchTaxItem: (id) => {
      const tax_fetch_item_request = {
        dispatch,
        request: ["pricing", "tax", "read"],
        request_info: { id },
        auth: true,
      };
      utils.action(tax_fetch_item_request);
    },
    createTaxItem: async (payload) => {
      const tax_create_item_request = {
        dispatch,
        request: ["pricing", "tax", "create"],
        request_info: payload,
        auth: true,
      };
      await utils.action(tax_create_item_request);
    },
    updateTaxItem: async (id, payload) => {
      const tax_update_item_request = {
        dispatch,
        request: ["pricing", "tax", "update"],
        request_info: { id, ...payload },
        auth: true,
      };
      await utils.action(tax_update_item_request);
    },
    deleteTaxItem: async (id) => {
      const tax_delete_item_request = {
        dispatch,
        request: ["pricing", "tax", "delete"],
        request_info: { id },
        auth: true,
      };
      await utils.action(tax_delete_item_request);
    },
  },
});

export const configurePricingDiscount = (utils, dispatch, state) => ({
  initialize: () => {},
  data: {
    discount_fetch_list: state["pricing"]?.discount_list,
    discount_fetch_item: state["pricing"]?.discount_item,
    discount_create_item: state["pricing"]?.discount_item,
    discount_update_item: state["pricing"]?.discount_item,
    discount_delete_item: state["pricing"]?.deleted_discount,
  },
  action: {
    fetchDiscountList: () => {
      const discount_fetch_list_request = {
        dispatch,
        request: ["pricing", "discount", "list"],
        request_info: {},
        auth: true,
      };
      utils.action(discount_fetch_list_request);
    },
    fetchDiscountItem: (id) => {
      const discount_fetch_item_request = {
        dispatch,
        request: ["pricing", "discount", "read"],
        request_info: { id },
        auth: true,
      };
      utils.action(discount_fetch_item_request);
    },
    createDiscountItem: async (payload) => {
      const discount_create_item_request = {
        dispatch,
        request: ["pricing", "discount", "create"],
        request_info: payload,
        auth: true,
      };
      await utils.action(discount_create_item_request);
    },
    updateDiscountItem: async (id, payload) => {
      const discount_update_item_request = {
        dispatch,
        request: ["pricing", "discount", "update"],
        request_info: { id, ...payload },
        auth: true,
      };
      await utils.action(discount_update_item_request);
    },
    deleteDiscountItem: async (id) => {
      const discount_delete_item_request = {
        dispatch,
        request: ["pricing", "discount", "delete"],
        request_info: { id },
        auth: true,
      };
      await utils.action(discount_delete_item_request);
    },
  },
});

export const configurePricingCoupon = (utils, dispatch, state) => ({
  initialize: () => {},
  data: {
    coupon_fetch_list: state["pricing"]?.coupon_list,
    coupon_fetch_item: state["pricing"]?.coupon_item,
    coupon_create_item: state["pricing"]?.coupon_item,
    coupon_update_item: state["pricing"]?.coupon_item,
    coupon_delete_item: state["pricing"]?.deleted_coupon,
  },
  action: {
    fetchCouponList: () => {
      const coupon_fetch_list_request = {
        dispatch,
        request: ["pricing", "coupon", "list"],
        request_info: {},
        auth: false,
      };
      utils.action(coupon_fetch_list_request);
    },
    fetchCouponItem: (id) => {
      const coupon_fetch_item_request = {
        dispatch,
        request: ["pricing", "coupon", "read"],
        request_info: { id },
        auth: false,
      };
      utils.action(coupon_fetch_item_request);
    },
    createCouponItem: (payload) => {
      const coupon_create_item_request = {
        dispatch,
        request: ["pricing", "coupon", "create"],
        request_info: payload,
        auth: false,
      };
      utils.action(coupon_create_item_request);
    },
    updateCouponItem: (id, payload) => {
      const coupon_update_item_request = {
        dispatch,
        request: ["pricing", "coupon", "update"],
        request_info: { id, ...payload },
        auth: false,
      };
      utils.action(coupon_update_item_request);
    },
    deleteCouponItem: (id) => {
      const coupon_delete_item_request = {
        dispatch,
        request: ["pricing", "coupon", "delete"],
        request_info: { id },
        auth: false,
      };
      utils.action(coupon_delete_item_request);
    },
  },
});
