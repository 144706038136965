import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import { API_BASE_URL } from "App";
import { getCartItems, updateCartItems, getCartItemCount } from "services/cart";
import CheckoutForm from "./CheckoutForm";
import "./payment.css";

const stripePromise = loadStripe(
  "pk_test_51NxsC2I3C4ttZujyocvl5RpN9O0rqGUA3ZOjn9yJf9yLLPNgSo0b4BO2Pom0MXsgB47HnrmqGSeeBe1fvUd0f0BZ00ZCbf3KLp"
  // "pk_live_51NxsC2I3C4ttZujyw2FfXWuHhhGF3gNUnbYLSNwHZWZzgvMJsJtKY5Ki9YeL3OwarP5yf8nXzMawnIXqemmW257m00xiWXFWDN"
);

export default function App({ is_cart, product_data }) {
  const [cartItems, setCartItems] = useState(getCartItems());
  const [payload, setPayload] = useState();
  useEffect(() => {
    if (is_cart) {
      setPayload(cartItems);
    } else {
      setPayload([product_data]);
    }
  }, [is_cart]);

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if (payload) {
      const response = axios
        .post(`${API_BASE_URL}payment/create-payment-intent/`, payload)
        .then((res) => setClientSecret(res.data.clientSecret));
    }
  }, [payload]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="payment">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm is_cart={is_cart} product_data={product_data} />
        </Elements>
      )}
    </div>
  );
}
