import NoImage from "assets/no-image.png";

const ImageView = ({ url, height, width, style }) => {
  return (
    <div
      style={{
        backgroundImage: `url("${url || NoImage}")`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: height || "100vh",
        width: width || "100%",
        borderRadius: 10,
        ...style,
      }}
    />
  );
};

export default ImageView;
