import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  Button,
  Checkbox,
  Container,
  Form,
  Grid,
  Header,
  Input,
  Loader,
  Message,
  Modal,
  Radio,
  Segment,
  Table,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { configureAccountUserprofile } from "services/access/account";
import { utils } from "services/api-data/src/App";
import {
  configureOrderOrder,
  configureOrderOrderitem,
} from "services/access/order";
import { configureDeliveryDeliveryorder } from "services/access/delivery";
import { toast } from "react-toastify";
import { API_BASE_URL, SITE_URL } from "App";
import { Link } from "react-router-dom";

const PhoneModal = ({ children, openModal, setOpenModal }) => {
  return (
    <Modal
      centered
      open={openModal}
      onClose={() => setOpenModal(false)}
      dimmer="inverted"
    >
      <Modal.Header as={"h4"}> Phone Details </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
    </Modal>
  );
};

export default function CheckoutForm({ is_cart, product_data }) {
  const stripe = useStripe();
  const elements = useElements();
  const user_id = localStorage.getItem("user_id");
  const [profileData, setProfileData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState({
    city: "",
    address: "",
    postalCode: "",
    country: "",
    state: "",
  });
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureAccountUserprofile(
    utils,
    dispatch,
    state
  );

  const orderConfig = configureOrderOrder(utils, dispatch, state);
  const orderItemConfig = configureOrderOrderitem(utils, dispatch, state);
  const delieveryConfig = configureDeliveryDeliveryorder(
    utils,
    dispatch,
    state
  );

  useEffect(() => {
    if (user_id) {
      action.fetchUserprofileItem(user_id);
    }
  }, [user_id]);

  useEffect(() => {
    if (data?.userprofile_fetch_item) {
      const fetchedProfile = data.userprofile_fetch_item;
      setProfileData(fetchedProfile);
      setPhoneNumber(fetchedProfile?.contact_number);

      const profileAddress = {
        city: fetchedProfile.city,
        address: fetchedProfile.address,
        postalCode: fetchedProfile.postal_code,
        country: fetchedProfile.country,
        state: fetchedProfile.state,
      };
      setAddresses([profileAddress]);
      if (fetchedProfile?.contact_number === "") {
        setOpenModal(true);
      }
      setSelectedAddress(profileAddress);
    }
  }, [data?.userprofile_fetch_item]);

  useEffect(() => {
    if (phoneNumber === "" || user_id === null) {
      setOpenModal(true);
    }
  }, [phoneNumber]);

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const calculateTotalAmount = () => {
    if (is_cart) {
      return product_data
        .map((c) => c.final_price * c.quantity)
        .reduce((a, b) => parseInt(a) + parseInt(b), 0);
    } else {
      return product_data.final_price * product_data.quantity;
    }
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    try {
      const total_amount = calculateTotalAmount();

      const orderResponse = await orderConfig.action?.createOrderItem(
        {
          total_amount: total_amount,
        },
        user_id
      );
    } catch (e) {
      console.log(e);
    } finally {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleOrderCreation = async () => {
      const order_id = orderConfig?.data?.order_create_item?.id;
      if (!order_id) {
        toast.error("Order isn't created");
        return;
      }

      try {
        const orderItemsPromises = (
          is_cart ? product_data : [product_data]
        ).map((product) =>
          orderItemConfig.action.createOrderitemItem({
            order: order_id,
            product: is_cart ? product.product : product.id,
            quantity: product.quantity,
            unit_price: product.final_price,
          })
        );
        await Promise.all(orderItemsPromises);

        const deliveryOrder =
          await delieveryConfig.action.createDeliveryorderItem({
            order: order_id,
            address: selectedAddress.address,
            city: selectedAddress.city,
            state: selectedAddress.state,
            postal_code: selectedAddress.postalCode,
            country: selectedAddress.country,
          });

        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${SITE_URL}/checkout/checkout-success/${order_id}`,
          },
        });

        if (error) {
          if (
            error.type === "card_error" ||
            error.type === "validation_error"
          ) {
            setMessage(error.message);
            orderConfig?.action.deleteOrderItem(order_id);
          } else {
            setMessage("An unexpected error occurred.");
          }
        }
        setDataLoading(false);
      } catch (error) {
        console.error("Error during order creation:", error);
        // toast.error("An error occurred during the order creation process.");
      }
    };

    if (orderConfig?.data?.order_create_item?.id) {
      handleOrderCreation();
      setDataLoading(true);
    }
  }, [orderConfig?.data?.order_create_item?.id]);

  const handleNewAddressChange = (e) => {
    const { name, value } = e.target;
    setNewAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleAddNewAddress = () => {
    setAddresses([...addresses, newAddress]);
    setSelectedAddress(newAddress);
    setNewAddress({
      city: "",
      address: "",
      postalCode: "",
      country: "",
      state: "",
    });
    setShowNewAddressForm(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  const formatAddress = (address) => {
    const { address: addr, city, state, postalCode, country } = address;
    const parts = [addr, city, state, postalCode, country].filter(
      (part) => part && part.trim() !== ""
    );
    return parts.join(", ");
  };

  const currency = "€";

  return (
    <>
      <Header as={"h3"}> Product Details </Header>
      <Grid doubling celled columns={"equal"}>
        {is_cart ? (
          <>
            {product_data?.map((product) => (
              <Grid.Row>
                <Grid.Column> {product?.name} </Grid.Column>
                <Grid.Column> {product?.quantity} </Grid.Column>
                <Grid.Column> {product?.final_price} </Grid.Column>
              </Grid.Row>
            ))}
          </>
        ) : (
          <>
            <Grid.Row>
              <Grid.Column> {product_data?.name} </Grid.Column>
              <Grid.Column> {product_data?.quantity} </Grid.Column>
              <Grid.Column> {product_data?.final_price} </Grid.Column>
            </Grid.Row>
          </>
        )}
        <Grid.Row as={Header}>
          <Grid.Column> Total Price </Grid.Column>
          <Grid.Column>
            {" "}
            {currency} {calculateTotalAmount()}{" "}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid doubling divided="vertically" columns={"equal"}>
        <Grid.Row>
          <Grid.Column>
            <Grid.Row>
              <Segment>
                <Header as={"h4"}> Delivery Address </Header>
                <Container>
                  <Message info>
                    {" "}
                    To get your delivery in time please add your precise
                    address.{" "}
                  </Message>
                  {addresses.length > 0 ? (
                    addresses.map((address, index) => {
                      const formattedAddress = formatAddress(address);
                      if (formattedAddress) {
                        return (
                          <Container as={Header} key={index}>
                            <Radio
                              label={formattedAddress}
                              name="address"
                              value={address}
                              checked={selectedAddress === address}
                              onChange={() => setSelectedAddress(address)}
                            />
                          </Container>
                        );
                      }
                      return null;
                    })
                  ) : (
                    <Message as="h5" color="red">
                      Please add a new address.
                    </Message>
                  )}
                </Container>
                <Modal
                  open={showNewAddressForm}
                  onClose={() => setShowNewAddressForm(false)}
                  dimmer="inverted"
                  size="small"
                >
                  <Modal.Header> Set New Address </Modal.Header>
                  <Modal.Content>
                    <Form>
                      <Form.Group widths={"equal"}>
                        <Form.Input
                          label="Address"
                          name="address"
                          value={newAddress.address}
                          onChange={handleNewAddressChange}
                        />
                        <Form.Input
                          label="City"
                          name="city"
                          value={newAddress.city}
                          onChange={handleNewAddressChange}
                        />
                        <Form.Input
                          label="State"
                          name="state"
                          value={newAddress.state}
                          onChange={handleNewAddressChange}
                        />
                      </Form.Group>
                      <Form.Group widths={"equal"}>
                        <Form.Input
                          label="Postal Code"
                          name="postalCode"
                          value={newAddress.postalCode}
                          onChange={handleNewAddressChange}
                        />
                        <Form.Input
                          label="Country"
                          name="country"
                          value={newAddress.country}
                          onChange={handleNewAddressChange}
                        />
                      </Form.Group>
                      <Button onClick={handleAddNewAddress}>
                        Add New Address
                      </Button>
                    </Form>
                  </Modal.Content>
                </Modal>
                <Button onClick={() => setShowNewAddressForm(true)}>
                  Add New Address
                </Button>
              </Segment>{" "}
            </Grid.Row>
          </Grid.Column>
          <Grid.Column>
            <form id="payment-form" onSubmit={handleSubmit}>
              <PaymentElement
                id="payment-element"
                options={paymentElementOptions}
              />
              <button
                disabled={isLoading || !stripe || !elements || dataLoading}
                id="submit"
              >
                <span id="button-text">
                  {isLoading || dataLoading ? (
                    <div className="spinner" id="spinner"></div>
                  ) : (
                    "Pay now"
                  )}
                </span>
              </button>
              {/* Show any error or success messages */}
              {message && <div id="payment-message">{message}</div>}
            </form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <PhoneModal openModal={openModal} setOpenModal={setOpenModal}>
        {user_id !== null ? (
          <Message info>
            {" "}
            It looks like you havent added your phone number. Visit your profile
            and add your contact information{" "}
            <p>
              <Button
                fluid
                secondary
                as="a"
                href="/profile"
                target="_blank"
                rel="noopener noreferrer"
                inverted
                onClick={() => setOpenModal(false)}
              >
                Go to Profile Page
              </Button>
            </p>
          </Message>
        ) : (
          <Form>
            <Form.Field>
              <label>Enter your phone number:</label>
              <Input
                type="tel"
                placeholder="Phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Field>
            <Button
              fluid
              secondary
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Submit
            </Button>
          </Form>
        )}
      </PhoneModal>
    </>
  );
}
