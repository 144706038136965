import ProfileCard from "components/common/ProfileCard";
import MyProfile from "pages/Profile";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Container,
  Header,
  Form,
  Button,
  Table,
  Modal,
  Icon,
  Loader,
  Confirm,
} from "semantic-ui-react";
import { configureAccountUserprofile } from "services/access/account";
import {
  configurePricingDiscount,
  configurePricingTax,
} from "services/access/pricing";
import { utils } from "services/api-data/src/App";

const CommonModal = ({
  children,
  open,
  handleClose,
  handleSave,
  title,
  btn_name,
}) => {
  return (
    <Modal open={open} size="tiny" closeIcon onClose={handleClose}>
      <Modal.Header> {title} </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave} primary>
          {btn_name}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const TaxList = () => {
  const [taxes, setTaxes] = useState([]);
  const initial_state = {
    id: null,
    title: "",
    description: "",
    value: "",
  };
  const [currentTax, setCurrentTax] = useState(initial_state);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configurePricingTax(
    utils,
    dispatch,
    state
  );

  useEffect(() => {
    action.fetchTaxList();
  }, []);

  useEffect(() => {
    if (data?.tax_fetch_list) {
      setTaxes(data?.tax_fetch_list);
    }
  }, [data?.tax_fetch_list]);

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [triggerAction, setTriggerAction] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentTax((prevTax) => ({
      ...prevTax,
      [name]: value,
    }));
  };

  const handleEditOpen = (tax) => {
    setEditModal(true);
    setCurrentTax(tax);
  };

  const handleAddTax = async () => {
    setLoading(true);
    await action.createTaxItem({
      title: currentTax?.title,
      description: currentTax?.description,
      value: currentTax?.value,
    });
    setAddModal(false);
    toast.success("Tax added Successfully");
    setTriggerAction(true);
  };

  const handleUpdateTax = async () => {
    setLoading(true);
    const updatedValue = {
      title: currentTax?.title,
      description: currentTax?.description,
      value: currentTax?.value,
    };
    await action.updateTaxItem(currentTax.id, updatedValue);
    setEditModal(false);
    setCurrentTax(initial_state);
    toast.success(`${currentTax.title} has been updated`);
    setTriggerAction(true);
  };

  useEffect(() => {
    if (triggerAction) {
      action.fetchTaxList();
      setTriggerAction(false);
      setLoading(false);
    }
  }, [triggerAction]);

  const handleDeleteModal = (tax) => {
    setConfirmDelete(true);
    setCurrentTax(tax);
  };

  const handleDelete = async () => {
    setLoading(true);
    await action.deleteTaxItem(currentTax.id);
    toast.info(`${currentTax.title} has been removed`);
    setCurrentTax(initial_state);
    setTriggerAction(true);
    setConfirmDelete(false);
  };

  return (
    <Container style={{ marginTop: "50px" }}>
      <Header as={"h2"}>
        Tax
        <Button
          style={{ marginLeft: 10 }}
          primary
          floated="right"
          onClick={() => setAddModal(true)}
        >
          Add Tax
        </Button>
      </Header>
      <Table celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell> Description </Table.HeaderCell>
            <Table.HeaderCell>Rate (%)</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            <Loader active inline />
          ) : taxes?.length > 0 ? (
            taxes?.map((tax) => (
              <Table.Row key={tax.id}>
                <Table.Cell>{tax.title}</Table.Cell>
                <Table.Cell> {tax.description} </Table.Cell>
                <Table.Cell>{tax.value}</Table.Cell>
                <Table.Cell>
                  <Button
                    basic
                    primary
                    icon
                    onClick={() => handleEditOpen(tax)}
                  >
                    <Icon name="edit alternate" />
                  </Button>
                  <Button
                    icon
                    basic
                    negative
                    onClick={() => handleDeleteModal(tax)}
                  >
                    <Icon name="trash alternate" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={4}>
                {" "}
                <h3> No Taxes Found </h3>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      <CommonModal
        title={"Add Tax"}
        open={addModal}
        handleClose={() => setAddModal(false)}
        btn_name={"Add"}
        handleSave={handleAddTax}
      >
        <Form>
          <Form.Input
            label="Name"
            name="title"
            value={currentTax.title}
            onChange={handleChange}
          />
          <Form.Input
            label="Description"
            name="description"
            value={currentTax.description}
            onChange={handleChange}
          />
          <Form.Input
            label="Rate (%)"
            name="value"
            value={currentTax.value}
            onChange={handleChange}
          />
        </Form>
      </CommonModal>

      <CommonModal
        title={"Edit Tax"}
        open={editModal}
        handleClose={() => {
          setEditModal(false);
          setCurrentTax(initial_state);
        }}
        btn_name={"Update"}
        handleSave={handleUpdateTax}
      >
        <Form>
          <Form.Input
            label="Name"
            name="title"
            value={currentTax.title}
            onChange={handleChange}
          />
          <Form.Input
            label="Description"
            name="description"
            value={currentTax.description}
            onChange={handleChange}
          />
          <Form.Input
            label="Rate (%)"
            name="value"
            value={currentTax.value}
            onChange={handleChange}
          />
        </Form>
      </CommonModal>

      <Confirm
        open={confirmDelete}
        header={"Delete this tax"}
        content={
          <div style={{ padding: "25px" }}>
            Are you sure you want to delete this tax <b>{currentTax?.title}</b>?
          </div>
        }
        onCancel={() => {
          setConfirmDelete(false);
          setCurrentTax(initial_state);
        }}
        onConfirm={handleDelete}
      />
    </Container>
  );
};

const DiscountList = () => {
  const [discounts, setDiscounts] = useState();
  const initial_state = {
    id: null,
    percent: 0,
    amount: 0,
    description: "",
  };
  const [currentDiscount, setCurrentDiscount] = useState(initial_state);

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configurePricingDiscount(
    utils,
    dispatch,
    state
  );

  useEffect(() => {
    action.fetchDiscountList();
  }, []);

  useEffect(() => {
    if (data?.discount_fetch_list) {
      setDiscounts(data?.discount_fetch_list);
    }
  }, [data?.discount_fetch_list]);

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [triggerAction, setTriggerAction] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentDiscount((prevDiscount) => ({
      ...prevDiscount,
      [name]: value,
    }));
  };

  const handleAddDiscount = async () => {
    setLoading(true);
    await action.createDiscountItem({
      title: currentDiscount?.title,
      description: currentDiscount?.description,
      percent: currentDiscount?.percent,
      amount: currentDiscount?.amount,
    });
    setAddModal(false);
    setCurrentDiscount(initial_state);
    toast.success("Discount added Successfully");
    setTriggerAction(true);
  };

  const handleUpdateDiscount = async () => {
    setLoading(true);
    const updatedValue = {
      title: currentDiscount?.title,
      description: currentDiscount?.description,
      percent: currentDiscount?.percent,
      amount: currentDiscount?.amount,
    };
    await action.updateDiscountItem(currentDiscount.id, updatedValue);
    setEditModal(false);
    setCurrentDiscount(initial_state);
    toast.success(`${currentDiscount.title} has been updated`);
    setTriggerAction(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    await action.deleteDiscountItem(currentDiscount.id);
    toast.info(`${currentDiscount.title} has been removed`);
    setCurrentDiscount(initial_state);
    setTriggerAction(true);
    setConfirmDelete(false);
  };

  const handleDeleteModal = (discount) => {
    setConfirmDelete(true);
    setCurrentDiscount(discount);
  };

  const handleEditModal = (discount) => {
    setEditModal(true);
    setCurrentDiscount(discount);
  };

  useEffect(() => {
    if (triggerAction) {
      action.fetchDiscountList();
      setTriggerAction(false);
      setLoading(false);
    }
  }, [triggerAction]);

  return (
    <Container style={{ marginTop: "50px" }}>
      <Header as={"h2"}>
        Discount
        <Button
          style={{ marginLeft: 10 }}
          primary
          floated="right"
          onClick={() => setAddModal(true)}
        >
          Add Discount
        </Button>
      </Header>
      <Table celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell> Description </Table.HeaderCell>
            <Table.HeaderCell> Amount </Table.HeaderCell>
            <Table.HeaderCell>Rate (%)</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            <Loader active inline />
          ) : discounts?.length > 0 ? (
            discounts?.map((discount) => (
              <Table.Row key={discount.id}>
                <Table.Cell>{discount.title}</Table.Cell>
                <Table.Cell>{discount.description}</Table.Cell>

                <Table.Cell>
                  {discount?.amount ? discount?.amount : "-"}
                </Table.Cell>

                <Table.Cell>
                  {discount?.percent ? discount?.percent : "-"}
                </Table.Cell>
                <Table.Cell>
                  <Button
                    basic
                    primary
                    icon
                    onClick={() => handleEditModal(discount)}
                  >
                    <Icon name="edit alternate" />
                  </Button>
                  <Button
                    icon
                    basic
                    negative
                    onClick={() => handleDeleteModal(discount)}
                  >
                    <Icon name="trash alternate" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={5}>
                {" "}
                <h3> No Discount Found </h3>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      <CommonModal
        title={"Add Discount"}
        open={addModal}
        handleClose={() => setAddModal(false)}
        btn_name={"Add"}
        handleSave={handleAddDiscount}
      >
        <Form>
          <Form.Input
            label="Name"
            name="title"
            value={currentDiscount.title}
            onChange={handleChange}
          />
          <Form.Input
            label="Description"
            name="description"
            value={currentDiscount.description}
            onChange={handleChange}
          />

          <Form.Input
            label="Amount"
            name="amount"
            value={currentDiscount.amount}
            onChange={handleChange}
          />
          <Form.Input
            label="Rate (%)"
            name="percent"
            value={currentDiscount.percent}
            onChange={handleChange}
          />
        </Form>
      </CommonModal>

      <CommonModal
        title={"Edit Discount"}
        open={editModal}
        handleClose={() => {
          setEditModal(false);
          setCurrentDiscount(initial_state);
        }}
        btn_name={"Update"}
        handleSave={handleUpdateDiscount}
      >
        <Form>
          <Form.Input
            label="Name"
            name="title"
            value={currentDiscount.title}
            onChange={handleChange}
          />
          <Form.Input
            label="Description"
            name="description"
            value={currentDiscount.description}
            onChange={handleChange}
          />

          <Form.Input
            label="Amount"
            name="amount"
            value={currentDiscount.amount}
            onChange={handleChange}
          />
          <Form.Input
            label="Rate (%)"
            name="percent"
            value={currentDiscount.percent}
            onChange={handleChange}
          />
        </Form>
      </CommonModal>

      <Confirm
        open={confirmDelete}
        header={"Delete this discount"}
        content={
          <div style={{ padding: "25px" }}>
            Are you sure you want to delete this discount{" "}
            <b>{currentDiscount?.title}</b>?
          </div>
        }
        onCancel={() => {
          setConfirmDelete(false);
          setCurrentDiscount(initial_state);
        }}
        onConfirm={handleDelete}
      />

      {/* <Modal size="tiny" closeIcon open={open} onClose={handleClose}>
        <Modal.Header>
          {currentDiscount.id ? "Edit Discount" : "Add Discount"}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input
              label="Name"
              name="name"
              value={currentDiscount.name}
              onChange={handleChange}
            />
            <Form.Input
              label="Rate (%)"
              name="rate"
              value={currentDiscount.rate}
              onChange={handleChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} primary>
            Save
          </Button>
        </Modal.Actions>
      </Modal> */}
    </Container>
  );
};

const AdminSettingsPage = () => {
  // Dummy settings data
  const [profileData, setProfileData] = useState();
  const user_id = localStorage.getItem("user_id");
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureAccountUserprofile(
    utils,
    dispatch,
    state
  );

  useEffect(() => {
    action.fetchUserprofileItem(user_id);
  }, [user_id]);
  const [settings, setSettings] = useState({
    companyName: "",
    email: "",
    address: "",
    phone: "",
    currency: "USD",
    timezone: "UTC",
  });

  useEffect(() => {
    if (data?.userprofile_fetch_item) {
      setProfileData(data?.userprofile_fetch_item);
    }
  }, [data?.userprofile_fetch_item]);

  useEffect(() => {
    if (profileData) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        companyName: profileData.business_name,
        email: profileData.email,
        phone: profileData.contact_number,
        address: `${profileData?.country} ,
        ${profileData?.state} , ${profileData?.city}
        ${profileData?.address} ,
        ${profileData?.postal_code} `,
      }));
    }
  }, [profileData]);

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Dummy logic to submit settings data to backend
    console.log("Settings submitted:", settings);
  };

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  return (
    <Container>
      <Header as="h1">Settings</Header>
      {/* <Form onSubmit={handleSubmit}>
        <Form.Field>
          <label>Company Name</label>
          <input
            name="companyName"
            value={settings.companyName}
            onChange={handleChange}
            placeholder="Company Name"
          />
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <input
            name="email"
            value={settings.email}
            onChange={handleChange}
            placeholder="Email"
          />
        </Form.Field>
        <Form.Field>
          <label>Address</label>
          <input
            name="address"
            value={settings.address}
            onChange={handleChange}
            placeholder="Address"
          />
        </Form.Field>
        <Form.Field>
          <label>Phone</label>
          <input
            name="phone"
            value={settings.phone}
            onChange={handleChange}
            placeholder="Phone"
          />
        </Form.Field>
        <Form.Field>
          <label>Currency</label>
          <input
            name="currency"
            value={settings.currency}
            onChange={handleChange}
            placeholder="Currency"
          />
        </Form.Field>
        <Form.Field>
          <label>Timezone</label>
          <input
            name="timezone"
            value={settings.timezone}
            onChange={handleChange}
            placeholder="Timezone"
          />
        </Form.Field>
        <Button primary type="submit">
          Save Settings
        </Button>
      </Form> */}
      <ProfileCard />
      <TaxList />
      <DiscountList />
    </Container>
  );
};

export default AdminSettingsPage;
