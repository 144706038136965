export const PAGE_LIMIT = 5;
const BIG_LIMIT = 1000;

export const configureOrderOrder = (utils, dispatch, state) => ({
  initialize: () => {
    const order_fetch_list_request = {
      dispatch,
      request: ["order", "order", "list"],
      request_info: { limit: PAGE_LIMIT, offset: 0 },
      auth: true,
      key: "order_page_1",
    };
    utils.action(order_fetch_list_request);
  },
  data: {
    order: state["order"],
    order_fetch_list: state["order"]?.order_list,
    order_fetch_item: state["order"]?.order_item,
    order_create_item: state["order"]?.order_item,
    order_update_item: state["order"]?.order_item,
    order_delete_item: state["order"]?.deleted_order,
  },
  action: {
    fetchOrderList: () => {
      const order_fetch_list_request = {
        dispatch,
        request: ["order", "order", "list"],
        request_info: {},
        auth: true,
      };
      utils.action(order_fetch_list_request);
    },
    fetchOrderItem: (id, user_id) => {
      console.log(user_id);
      const order_fetch_item_request = {
        dispatch,
        request: ["order", "order", "read"],
        request_info: { id },
        auth: user_id !== null ? true : false,
      };
      utils.action(order_fetch_item_request);
    },
    getPages: (number) => {
      const order_fetch_list_request = {
        dispatch,
        request: ["order", "order", "list"],
        request_info: {
          limit: PAGE_LIMIT,
          offset: PAGE_LIMIT * ((number || 1) - 1),
        },
        auth: true,
        key: `order_page_${number}`,
      };
      utils.action(order_fetch_list_request);
    },
    createOrderItem: (payload, user_id) => {
      const order_create_item_request = {
        dispatch,
        request: ["order", "order", "create"],
        request_info: payload,
        auth: user_id ? true : false,
      };
      return utils.action(order_create_item_request);
    },
    updateOrderItem: async (id, payload) => {
      const order_update_item_request = {
        dispatch,
        request: ["order", "order", "update"],
        request_info: { id, ...payload },
        auth: true,
      };
      await utils.action(order_update_item_request);
    },
    deleteOrderItem: (id) => {
      const order_delete_item_request = {
        dispatch,
        request: ["order", "order", "delete"],
        request_info: { id },
        auth: true,
      };
      utils.action(order_delete_item_request);
    },
  },
});

export const configureOrderOrderitem = (utils, dispatch, state) => ({
  initialize: () => {},
  data: {
    orderitem_fetch_list: state["order"]?.orderitem_list,
    orderitem_fetch_item: state["order"]?.orderitem_item,
    orderitem_create_item: state["order"]?.orderitem_item,
    orderitem_update_item: state["order"]?.orderitem_item,
    orderitem_delete_item: state["order"]?.deleted_orderitem,
  },
  action: {
    fetchOrderitemList: (id) => {
      const orderitem_fetch_list_request = {
        dispatch,
        request: ["order", "orderitem", "list"],
        request_info: { order: id },
        auth: true,
      };
      utils.action(orderitem_fetch_list_request);
    },
    fetchOrderitemItem: (id) => {
      const orderitem_fetch_item_request = {
        dispatch,
        request: ["order", "orderitem", "read"],
        request_info: { id },
        auth: true,
      };
      utils.action(orderitem_fetch_item_request);
    },
    createOrderitemItem: (payload) => {
      const orderitem_create_item_request = {
        dispatch,
        request: ["order", "orderitem", "create"],
        request_info: payload,
        auth: false,
      };
      utils.action(orderitem_create_item_request);
    },
    updateOrderitemItem: (id, payload) => {
      const orderitem_update_item_request = {
        dispatch,
        request: ["order", "orderitem", "update"],
        request_info: { id, ...payload },
        auth: true,
      };
      utils.action(orderitem_update_item_request);
    },
    deleteOrderitemItem: (id) => {
      const orderitem_delete_item_request = {
        dispatch,
        request: ["order", "orderitem", "delete"],
        request_info: { id },
        auth: true,
      };
      utils.action(orderitem_delete_item_request);
    },
  },
});
